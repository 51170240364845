import {
  Medium,
  SubTitle2,
} from "@vestaboard/installables/lib/components/Typography";
import {
  Button,
  Divider,
  Input,
  Spacer,
} from "@vestaboard/installables";
import { Box, makeStyles } from "@material-ui/core";

import useLocalStorage from "use-local-storage";
import { getBitsFromImage } from "../api";

const useStyles = makeStyles({
  section: {
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    paddingTop: 24,
    marginLeft: 16,
    marginRight: 16,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    padding: 16,
    paddingTop: 32,
    paddingBottom: 32,
  },
  descriptionContainer: {
    textAlign: "left",
    width: "100%",
    maxWidth: 600,
  },
});

export const Settings = () => {
  const classes = useStyles();
  const [boardId, setBoardId] = useLocalStorage('boardId', '')
  const [imageUrl, setImageUrl] = useLocalStorage('imageUrl', 'https://blog.hubspot.com/hubfs/image8-2.jpg')
  return (
    <>
      <Box className={classes.content}> 
        <Box className={classes.section}>
          <Divider />
          <Spacer size={"medium"} />
          <SubTitle2>Image to Bit Tool</SubTitle2>
          <Spacer size={"medium"} />
          <Medium>Board</Medium>
          <Input
            placeholder="BoardId"
            onValueChange={(text) => setBoardId(text)}
            value={boardId}
          ></Input>
          <Spacer size={"medium"} />
          <Medium>Image Url</Medium>
          <Input
            placeholder="Image Url"
            onValueChange={(text) => setImageUrl(text)}
            value={imageUrl}
          ></Input>
          <Spacer size={"medium"} />
          <Button
            buttonType="white"
            disabled={!boardId || !imageUrl}
            onClick={async () => {
              const {composeUrl} = await getBitsFromImage(boardId, imageUrl)
              window.open(composeUrl)
            }}
          >
            {"Get Bits From Image"}
          </Button>
          <Spacer size={"large"} />
        </Box>
        <Spacer size={"large"} />
      </Box>
    </>
  );
};
