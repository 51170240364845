import { Settings } from "./pages/Settings";
import { Theme } from "@vestaboard/installables/lib/components/Theme";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
        <Settings />
    ),
  },
]);

const App = () => {
  return (
    <>
      <Theme>
          <RouterProvider router={router} />
      </Theme>
    </>
  );
};

export default App;
