import axios from "axios";


export const getBitsFromImage = async (boardId: string, imageUrl: string) => {
  const response = await axios.post(
    '/api/bits',
    {
        imageUrl,
        boardId
    },
    {
        headers: {
            'Content-Type': 'application/json'
        }
    })

    return response.data as {
      composeUrl: string,
      charachters: Array<Array<number>>
    }
}